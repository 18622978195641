export const isEDMode =
    typeof window !== 'undefined' ? window.location.href.indexOf('ed=1') > 0 : false;
export const mobileMedia = '(min-width: 0px) and (max-width: 1200px)';
export const tabletMedia = '(min-width: 0px) and (max-width: 1024px)';

export const isMobile = () => {
    if (typeof window !== 'undefined') {
        return window.matchMedia(mobileMedia).matches;
    }
    return true;
};

export const isTablet = (media = tabletMedia) => {
    if (typeof window !== 'undefined') {
        return window.matchMedia(media).matches;
    }
    return true;
};

export const bounds = (elem) => {
    const rect = elem.getBoundingClientRect();

    return {
        top: rect.top,
        left: rect.left,
        right: rect.right,
        bottom: rect.bottom,
        width: rect.width,
        height: rect.height,
    };
};

export const getWinSize = () => {
    if (typeof window !== 'undefined') {
        const w = window;
        const d = document;
        const e = d.documentElement;
        const g = d.getElementsByTagName('body')[0];
        const x = w.innerWidth || e.clientWidth || g.clientWidth;
        const y = w.innerHeight || e.clientHeight || g.clientHeight;

        return { width: x, height: y };
    }

    return { width: 0, height: 0 };
};

export const inViewport = (elem, entireBox = false, xOffset = 0, yOffset = 0) => {
    if (typeof window !== 'undefined') {
        const bds = bounds(elem);
        const doc = getWinSize();

        if (!entireBox) {
            return bds.top >= 0 && bds.left >= 0 && bds.top <= doc.height && bds.left <= doc.width;
        } else {
            const topLimit = bds.top - doc.height;
            const bottomLimit = bds.top + bds.height;
            const leftLimit = bds.left - doc.width;
            const rightLimit = bds.left + bds.width;

            if (topLimit <= 0 && bottomLimit >= 0 && leftLimit <= 0 && rightLimit >= 0) {
                return true;
            } else {
                return false;
            }
        }
    }

    return false;
};

export const debounce = (func, wait, immediate) => {
    let timeout = null;

    return function () {
        const context = this;
        const args = arguments;

        const later = () => {
            timeout = null;

            if (!immediate) {
                func.apply(context, args);
            }
        };

        const callNow = immediate && !timeout;

        clearTimeout(timeout);

        timeout = setTimeout(later, wait);

        if (callNow) {
            func.apply(context, args);
        }
    };
};

export const getOS = () => {
    let OSName = 'unknown-os';

    if (navigator.appVersion.includes('Win')) OSName = 'win';
    if (navigator.appVersion.includes('Mac')) OSName = 'mac';
    if (navigator.appVersion.includes('X11')) OSName = 'unix';
    if (navigator.appVersion.includes('iPhone')) OSName = 'ios';
    if (navigator.appVersion.includes('iPad')) OSName = 'ios';
    if (navigator.appVersion.includes('Linux')) OSName = 'linux';

    return OSName;
};

export const getUrlParameter = (name) => {
    name = name.replace(/[\\[]/, '\\[').replace(/[\]]/, '\\]');
    const regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    const results = regex.exec(window.location.search);

    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
};

export const extraSpaceForMobileMenu = (v) => {
    document.querySelector('html').style.height = v;
    document.querySelector('html').style.overflow = 'hidden';
    document.querySelector('body').style.height = v;
    document.querySelector('body').style.overflow = 'hidden';
    document.querySelector('#root').style.height = v;
    document.querySelector('#root').style.overflow = 'hidden';
    document.querySelector('header nav ul.menu.top-menu').style.height = v;
};

export const removeSections = (v) => {
    let toRemove;
    if (v === 'get-offer') {
        toRemove = document.querySelectorAll(`header, section:not(#${v}), footer`);
    }

    if (v === 'footer') {
        toRemove = document.querySelectorAll(`main, header`);
    }

    setTimeout(() => {
        toRemove?.forEach((el) => el.remove());
    }, 0);
};

export const focusLoop = (e, selector) => {
    const tabPressed = e.key === 'Tab' || e.keyCode === 9;

    if (!tabPressed) return;

    const elems = document.querySelectorAll(selector);
    if (elems?.length) {
        const firstElem = elems[0];
        const lastElem = elems[elems.length - 1];

        if (!Array.from(elems).includes(document.activeElement)) {
            firstElem.focus();
            firstElem.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }

        if (e.shiftKey) {
            if (document.activeElement === firstElem) {
                lastElem.focus();
                lastElem.scrollIntoView({ behavior: 'smooth', block: 'center' });
                e.preventDefault();
            }
        } else {
            if (document.activeElement === lastElem) {
                firstElem.focus();
                firstElem.scrollIntoView({ behavior: 'smooth', block: 'center' });
                e.preventDefault();
            }
        }
    }
};

export const convertFileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            resolve(reader.result);
        };
        reader.onerror = (error) => reject(error);
    });
};

export const validateDate = (value) => {
    if (value < 10) {
        // invalid date if dateInput length is less than 10
        return false;
    }
    // splitter will split the date and get the date seperator eg: -
    // const splitter = value.replace(/[0-9]/g, '')[0];
    // using splitter will get the parts of the date
    const parts = value.split('/');

    // since year can be in front for yyyy-mm-dd and in last for dd-mm-yyyy taking care of that logic
    const year = parts.at(2);
    if (!year || year.length !== 4) return false;
    // month will be always in center
    const month = parts.at(0);
    // taking care of day for the different formats like yyyy-mm-dd or dd-mm-yyyy
    const day = parts.at(1);

    // creating date our of the year, month day
    const date = new Date(year, +month - 1, day);

    //validates leapyear and dates exceeding month limit
    const isValidDate =
        Boolean(+date) &&
        date.getDate() == day &&
        date.getMonth() + 1 == month &&
        date.getFullYear() == year;

    // isValid date is true if the date is valid else false
    return isValidDate;
};

export const postToDb = async (values, formName) => {
    // Save to our DynamoDB
    const payload = {
        ...values,
        dataSource: formName,
    };

    try {
        await fetch(`${process.env.GATSBY_API_URL}/sign-up`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(payload),
        });
    } catch (err) {
        console.log('err', err);
    }
};

// This fn formats 1112223333 to (111)-222-3333.
export const formatPhoneNumber = (v) => {
    const cleaned = v.replace(/\D/g, '');
    const part1 = cleaned.substring(0, 3);
    const part2 = cleaned.substring(3, 6);
    const part3 = cleaned.substring(6, 10);
    const formatted = `${
        (part1 && cleaned.length <= 3) || !cleaned.length ? part1 : '(' + part1 + ')'
    }${part2 ? '-' + part2 : ''}${part3 ? '-' + part3 : ''}`;
    return { formatted, cleaned };
};

export const scrollElToCenter = (e) => {
    if (e.key === 'Tab') {
        e.target.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
        });
    }
};

export const scrollToSection = (selector) => {
    const el = document.querySelector(selector);
    if (el) {
        el.scrollIntoView({ behavior: 'smooth' });
    }
};

export const base64ToArrayBuffer = (base64) => {
    var binaryString = atob(base64);
    var bytes = new Uint8Array(binaryString.length);
    for (var i = 0; i < binaryString.length; i++) {
        bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
};
